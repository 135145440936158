import { useState, useEffect } from 'react'

const useScroll = (): { scrollX: number | null; scrollY: number | null } => {
  const [scrollY, setScrollY] = useState<number | null>(null)
  const [scrollX, setScrollX] = useState<number | null>(null)

  useEffect(() => {
    window.addEventListener(`scroll`, scrollHandler, { passive: true })
    return () => {
      window.removeEventListener(`scroll`, scrollHandler)
    }
  })
  const scrollHandler = () => {
    setScrollX(window.scrollX)
    setScrollY(window.scrollY)
  }
  return { scrollX: scrollX, scrollY: scrollY }
}

export default useScroll
