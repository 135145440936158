import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContext } from 'react'
import { SearchContext } from '../contexts/searchContext'
import { useLocationOptions } from '../hooks/useLocationOptions'
import DatePicker from './datepicker'
import LoadingButton from './loading-button'
import ChevronRightRoundIcon from '../images/chevron-right-round.inline.svg'
import { RoomTypeAvailable } from '../../../../shared/types/cms'

const SearchBar: React.FC = () => {
  const { t } = useTranslation()
  const search = useContext(SearchContext)
  const { locations } = useLocationOptions()

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    search.doSearch()
  }

  const stats = React.useMemo(() => {
    const result = {
      roomTypes: search.roomTypes.length,
      rooms: search.roomTypes.reduce(
        (res: number, object: RoomTypeAvailable) => res + object.addresses?.length ?? 0,
        0,
      ),
    }
    return result
  }, [search.roomTypes])

  return (
    <div className="container-md search-bar__container">
      <form className="search-bar" onSubmit={handleSubmit}>
        <div className="search-bar__input-wrapper">
          <select
            className="search-bar__input"
            id="search-bar__location"
            name="location"
            value={search.location}
            onChange={(event) => search.setLocation(event.target.value)}
            placeholder={t(`Location`)}
          >
            {locations.map((location) => (
              <option key={`location_option_` + location.id} value={location.id}>
                {t(location.name)}
              </option>
            ))}
          </select>
        </div>

        <div className="search-bar__input-wrapper">
          <DatePicker
            id="search-bar__start-date"
            name="search-bar__start-date"
            className="search-bar__input"
            placeholderText={t(`Start of rental`)}
            value={search.startDate}
            onChange={search.setStartDate}
          />
        </div>

        <div className="search-bar__button-wrapper">
          <LoadingButton className="search-bar__button" type="submit" loading={search.isSearching}>
            {t(`Find room`)}
            <ChevronRightRoundIcon />
          </LoadingButton>
        </div>

        <div className="search-bar__result">
          <div className="search-bar__result-first">
            {t(`Results`)} {t(search.locationObject?.name ?? ``)}
          </div>
          <div className="search-bar__result-second">
            <span className="search-bar__result-number">{stats.roomTypes}</span>
            {` `}
            {t(`Room type`, { count: stats.roomTypes })} //{` `}
            <span className="search-bar__result-number">{stats.rooms}</span> {t(`Room`, { count: stats.rooms })}
          </div>
        </div>
      </form>

      <form className="search-bar__mobile" onSubmit={handleSubmit}>
        <div className="search-bar__input-wrapper">
          <select
            className="search-bar__input"
            id="search-bar__location"
            name="location"
            value={search.location}
            onChange={(event) => search.setLocation(event.target.value)}
            placeholder={t(`Location`)}
          >
            {locations.map((location) => (
              <option key={`location_option_` + location.id} value={location.id}>
                {t(location.name)}
              </option>
            ))}
          </select>
        </div>

        <div className="search-bar__input-wrapper">
          <DatePicker
            id="search-bar__start-date"
            name="search-bar__start-date"
            className="search-bar__input"
            placeholderText={t(`Start of rental`)}
            value={search.startDate}
            onChange={search.setStartDate}
          />
        </div>

        <div className="search-bar__button-wrapper">
          <LoadingButton className="search-bar__button" type="submit" loading={search.isSearching}>
            {t(`Find a room now`)}
            <ChevronRightRoundIcon />
          </LoadingButton>
        </div>

        <div className="search-bar__result">
          <div className="search-bar__result-first">
            {t(`Results`)} {t(search.locationObject?.name ?? ``)}
          </div>
          <div className="search-bar__result-second">
            <span className="search-bar__result-number">{stats.roomTypes}</span>
            {` `}
            {t(`Room type`, { count: stats.roomTypes })} //{` `}
            <span className="search-bar__result-number">{stats.rooms}</span> {t(`Room`, { count: stats.rooms })}
          </div>
        </div>
      </form>
      {/*<MobileSearch visible={searchUpdateVisible} onClose={() => setSearchUpdateVisible(false)} />*/}
    </div>
  )
}

export default SearchBar
