import * as React from 'react'
import MobileNav from './header/mobile-nav'

interface MobileHeaderProps {
  breakpoints?: string
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ breakpoints = `d-lg-none` }) => {
  return (
    <>
      <div className={`mobile-header ${breakpoints}`}>
        <MobileNav fluid />
      </div>
    </>
  )
}

export default MobileHeader
