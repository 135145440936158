import * as React from 'react'
import LanguageSelector from '../language-selector'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import { AuthContext, AuthStatus } from '../../contexts/authContext'
import { graphql, useStaticQuery } from 'gatsby'
import { Page } from '../../../../../shared/types/cms'
import CrossIcon from '../../images/icon-cross.inline.svg'
import LoginButton from '../login-button'

const query = graphql`
  query {
    allStrapiPages {
      edges {
        node {
          id
          title
          slug
          locale
          localizations {
            id
            locale
          }
          navigation {
            footer
            mobile
          }
        }
      }
    }
  }
`

const NavMenu: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen = false, onClose }) => {
  const { t } = useTranslation()
  const auth = React.useContext(AuthContext)
  const isSignedIn = auth.authStatus === AuthStatus.SignedIn
  const { setAuthFlowVisible, signOut } = React.useContext(AuthContext)
  const { allStrapiPages } = useStaticQuery(query)
  const { language } = useI18next()

  const menuItems = React.useMemo(() => {
    const items: Page[] = []
    allStrapiPages.edges.forEach(({ node }: { node: Page }) => {
      if (node?.navigation?.mobile && node?.locale === language) {
        if (node.slug.indexOf(`/`) !== 0) {
          node.slug = `/${node.slug}`
        }
        items.push(node)
      }
    })
    return items
  }, [allStrapiPages])

  React.useEffect(() => {
    isOpen
      ? document?.body?.classList.add(`zymma-modal__body--open`)
      : document?.body?.classList.remove(`zymma-modal__body--open`)
  }, [isOpen])

  return (
    <>
      {isOpen && (
        <div className="nav-menu__wrapper" onClick={onClose}>
          <div
            className="nav-menu"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className={`container`}>
              <div className="nav-menu__top">
                <LanguageSelector />
                <button className="nav-menu__close" onClick={onClose}>
                  <CrossIcon width={100} height={100} />
                </button>
                <LoginButton />
              </div>

              {isSignedIn && (
                <>
                  <div className="nav-menu__entry">
                    <Link to="/account">{t(`Profile`)}</Link>
                  </div>
                  <div className="nav-menu__entry">
                    <Link to="/account#payment">{t(`Payment info`)}</Link>
                  </div>
                  <div className="nav-menu__entry">
                    <Link to="/account#history">{t(`Contracts`)}</Link>
                  </div>
                  <div className="nav-menu__entry">
                    <a
                      href="#"
                      onClick={(e) => {
                        signOut()
                        e.preventDefault()
                      }}
                    >
                      {t(`Log out`)} <i className="icomoon icomoon-exit nav-menu__logout-icon" />
                    </a>
                  </div>
                </>
              )}

              {menuItems.map((item, index) => {
                return (
                  <div key={`mobile_menu_items_` + index} className="nav-menu__navigation-entry">
                    <Link to={item.slug}>{item.title}</Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NavMenu
