import { Error as APIError } from '../../../../../shared/types/cms'
import { CognitoService } from '../cognito/service'

type RequestFlags = {
  skipContentTypeHeader?: boolean
}

export abstract class CmsService<T> {
  protected abstract urlPrefix: string

  constructor(private cmsUrl: string, private cognitoService: CognitoService) {}

  protected async request<R>(url = ``, opts: RequestInit = {}, flags?: RequestFlags): Promise<R | APIError> {
    let token
    try {
      token = (await this.cognitoService.getSession())?.getAccessToken().getJwtToken()
    } catch (e) {
      console.log(e)
    }
    const headers = new Headers(opts.headers)

    if (token) {
      headers.append(`Authorization`, `Bearer ${token}`)
    }

    if (!flags?.skipContentTypeHeader) {
      headers.append(`Content-type`, `application/json`)
    }

    opts.headers = headers

    const response = await fetch(`${this.cmsUrl}/${this.urlPrefix}/${url}`, {
      ...opts,
    })

    if (response.ok) {
      return response.json()
    } else {
      return {
        statusCode: response.status,
        error: response.statusText,
        message: ``,
      } as APIError
    }
  }

  async getById(id: string | number): Promise<T> {
    const response = await this.request<T>(id.toString())
    if (`error` in response) {
      throw new Error(`Failed to retrieve element by id`)
    }

    return response
  }

  async getList(): Promise<T[]> {
    const response = await this.request<T[]>()
    if (`error` in response) {
      throw new Error(`Failed to retrieve element list`)
    }

    return response
  }
}
