import * as React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { PageContext } from '../contexts/pageContext'
import { CmsTenant } from '../services/cms'
import { SiteLocale } from '../../../../shared/types/cms'
import { useLocation } from '@reach/router'
import { AuthContext, AuthStatus } from '../contexts/authContext'

export const LanguageSelector: React.FC = () => {
  const { localizedPaths = undefined } = React.useContext(PageContext)
  const { language, languages, originalPath, changeLanguage } = useI18next()
  const windowLocation = useLocation()
  const authContext = React.useContext(AuthContext)

  const languagePaths = React.useMemo(() => {
    const result: Record<string, string> = {}
    languages.forEach(
      (lang) =>
        (result[lang] = !!localizedPaths && !!localizedPaths[lang] ? localizedPaths[lang] : originalPath) +
        windowLocation.search +
        windowLocation.hash,
    )
    return result
  }, [windowLocation, languages])

  React.useEffect(() => {
    if (authContext.tenant === null) {
      authContext.loadTenant()
    } else {
      if (authContext.tenant.locale && language !== authContext.tenant.locale) {
        changeLanguage(authContext.tenant.locale, languagePaths[authContext.tenant.locale])
      }
    }
  }, [authContext.tenant])

  return (
    <div className="lang-selector">
      {Object.keys(languagePaths).map((lang, index) => (
        <React.Fragment key={`language-option-${lang}`}>
          {index !== 0 && <span>//</span>}
          <Link
            className={`lang-selector__option ${lang === language ? `lang-selector__option--selected` : ``}`}
            to={languagePaths[lang]}
            language={lang}
            onClick={(event) => {
              event.preventDefault()

              if (authContext.authStatus === AuthStatus.SignedIn) {
                CmsTenant.updateLocale(lang as SiteLocale).then(() => changeLanguage(lang, languagePaths[lang]))
              } else {
                changeLanguage(lang, languagePaths[lang])
              }
            }}
          >
            {lang}
          </Link>
        </React.Fragment>
      ))}
    </div>
  )
}
export default LanguageSelector
