import {
  Contract,
  PaymentData,
  Tenant,
  UpdateTenant,
  CustomerPaymentMethod,
  Application,
  UpdateApplication,
  SiteLocale,
} from '../../../../../shared/types/cms'
import { CmsService } from './cms'
import { FileWithPreview } from '../../components/documents-uploader'

export enum PaymentMethodType {
  SEPA = `sepa_debit`,
  card = `card`,
}

export interface PaymentHistoryInterface {
  payments: PaymentData[]
  upcomingPayment?: PaymentData
  hasMore: boolean
}

export class CmsTenantService extends CmsService<Tenant> {
  urlPrefix = `tenants`

  async getMe(): Promise<Tenant> {
    const response = await this.request<Tenant>(`me`)
    if (`error` in response) {
      throw new Error(`Failed to retrieve tenant info`)
    }

    return response
  }

  async updateLocale(locale: SiteLocale): Promise<void> {
    const response = await this.request<Tenant>(`me`, {
      method: `PUT`,
      body: JSON.stringify({ locale }),
    })

    if (`error` in response) {
      throw new Error(`Failed to update tenant locale`)
    }
  }

  async updateMe(
    data: Omit<UpdateTenant, 'passportPhotos' | 'studentCertificate' | 'studentProofOfIncomeDocument'> & {
      passportPhotos?: FileWithPreview[]
      studentCertificate?: FileWithPreview[]
      studentProofOfIncomeDocument?: FileWithPreview[]
    },
  ): Promise<Tenant> {
    const formData = new FormData()

    if (data.passportPhotos) {
      data.passportPhotos.map((document, index) => {
        if (!document.id) {
          formData.append(`files.passportPhotos[${index}]`, document)
        }
      })

      data.passportPhotos = data.passportPhotos.filter((photo) => !!photo.id)
    }

    if (data.studentCertificate) {
      data.studentCertificate.map((document, index) => {
        if (!document.id) {
          formData.append(`files.studentCertificate[${index}]`, document)
        }
      })

      data.studentCertificate = data.studentCertificate.filter((photo) => !!photo.id)
    }

    if (data.studentProofOfIncomeDocument) {
      data.studentProofOfIncomeDocument.map((document, index) => {
        if (!document.id) {
          formData.append(`files.studentProofOfIncomeDocument[${index}]`, document)
        }
      })

      data.studentProofOfIncomeDocument = data.studentProofOfIncomeDocument.filter((photo) => !!photo.id)
    }

    formData.append(`data`, JSON.stringify(data))

    const response = await this.request<Tenant>(
      `me`,
      {
        method: `PUT`,
        body: formData,
      },
      { skipContentTypeHeader: true },
    )

    if (`error` in response) {
      throw new Error(`Failed to update tenant`)
    }
    return response
  }

  async deleteMe(): Promise<void> {
    const response = await this.request<Tenant>(`me`, {
      method: `DELETE`,
    })
    if (`error` in response) {
      throw new Error(`Something went wrong when deleting tenant`)
    }

    return
  }

  /*
  async setPaymentMethod(
    type: PaymentMethodType,
    iban: string,
    name: string,
    email: string,
  ): Promise<{ clientSecret: string; locationPk: string } | Error> {
    const paymentData = {
      paymentMethod: {
        type: type,
        sepa_debit: {
          iban: iban,
        },
        billing_details: {
          name: name,
          email: email,
        },
      },
    }

    return await this.request(`me/payment-method`, {
      method: `POST`,
      body: JSON.stringify(paymentData),
    })
  }
  */

  async createSetupIntent(): Promise<{ secret: string }> {
    const response = await this.request<{ secret: string }>(`create-setup-intent`)
    if (`error` in response) {
      throw new Error(`Failed to create stripe setup intent`)
    }

    return response
  }

  async getPaymentHistory({
    startingAfter,
    getUpcoming = false,
    limit = 10,
  }: {
    startingAfter?: string
    getUpcoming?: boolean
    limit: number
  }): Promise<PaymentHistoryInterface> {
    const params: Record<string, string> = {
      getUpcoming: getUpcoming.toString(),
      limit: limit.toString(),
    }
    if (startingAfter !== undefined) params[`startingAfter`] = startingAfter

    const paramString = new URLSearchParams(params).toString()

    const response = await this.request<PaymentHistoryInterface>(`me/payment-history?${paramString}`)
    if (`error` in response) {
      throw new Error(`Failed to retrieve payment history`)
    }

    return response
  }

  async getPaymentMethods(): Promise<CustomerPaymentMethod[]> {
    const response = await this.request<CustomerPaymentMethod>(`me/payment-methods`)
    if (`error` in response) {
      throw new Error(`Failed to retrieve payment methods`)
    }

    return Array.isArray(response) ? response : [response]
  }

  async getContracts(): Promise<Contract[]> {
    const response = await this.request<Contract[]>(`me/contracts`)
    if (`error` in response) {
      throw new Error(`Failed to retrieve contracts`)
    }

    return response
  }

  async getApprovedApplications(): Promise<Application[]> {
    const response = await this.request<Application[]>(`me/applications`)
    if (`error` in response) {
      throw new Error(`Failed to retrieve applications`)
    }

    return response
  }

  async updateApplication(data: UpdateApplication): Promise<Application> {
    const response = await this.request<Application>(`me/applications`, {
      method: `PUT`,
      body: JSON.stringify(data),
    })
    if (`error` in response) {
      throw new Error(`An error occured during application update`)
    }

    return response
  }
}
