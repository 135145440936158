import * as React from 'react'

interface MainLayoutProps {
  className?: string
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, className = ``, ...props }) => (
  <div className={`main-layout ` + className} {...props}>
    {children}
  </div>
)

export default MainLayout
