import * as React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import LogoZymma from '../../images/zymma-logo-neg.inline.svg'
import LanguageSelector from '../language-selector'
import LoginButton from '../login-button'
import useScroll from '../../hooks/useScroll'
import menuBurger from '../../images/menu-burger.svg'
import NavMenu from './nav-menu'
import { SearchContext } from '../../contexts/searchContext'
import { useLocationOptions } from '../../hooks/useLocationOptions'
import DatePicker from '../datepicker'
import LoadingButton from '../loading-button'

const StickyHeader: React.FC = () => {
  const { t } = useTranslation()
  const [visible, setVisible] = React.useState(false)
  const [menuVisible, setMenuVisible] = React.useState(false)
  const search = React.useContext(SearchContext)
  const { locations } = useLocationOptions()

  const { scrollY } = useScroll()
  React.useEffect(() => {
    const headerEl = document.querySelector(`header`)
    if (headerEl && scrollY !== null) {
      if (headerEl.getBoundingClientRect().bottom < -100) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    } else {
      setVisible(false)
    }
  }, [scrollY])

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    search.doSearch()
  }

  return (
    <nav className={`navbar sticky-header ${visible ? `` : `d-none`}`}>
      <div className="container-fluid sticky-header__container">
        <Link to="/" className="navbar-brand navbar__title">
          <LogoZymma />
        </Link>

        <div />
        {/*
        <form className="sticky-header__search" onSubmit={handleSubmit}>
          <div className="search-bar__input-wrapper">
            <select
              className="search-bar__input"
              id="sticky-header__search-location"
              name="location"
              value={search.location}
              onChange={(event) => search.setLocation(event.target.value)}
              placeholder={t(`Location`)}
            >
              {locations.map((location) => (
                <option key={`location_option_` + location.id} value={location.id}>
                  {t(location.name)}
                </option>
              ))}
            </select>
          </div>

          <div className="search-bar__input-wrapper">
            <DatePicker
              id="sticky-header__search-date"
              name="start-date"
              className="search-bar__input"
              placeholderText={t(`Start of rental`)}
              value={search.startDate}
              onChange={search.setStartDate}
            />
          </div>

          <div className="search-bar__button-wrapper">
            <LoadingButton
              className="sticky-header__search-button"
              type="submit"
              loading={search.isSearching}
              aria-label={t(`Find room`)}
            >
              <i className="icomoon icomoon-search" />
            </LoadingButton>
          </div>
        </form>
              */}

        <img className="sticky-header__burger cursor-pointer" src={menuBurger} onClick={() => setMenuVisible(true)} />
      </div>
      <NavMenu isOpen={menuVisible} onClose={() => setMenuVisible(false)} />
    </nav>
  )
}

export default StickyHeader
