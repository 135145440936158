import React from 'react'
import { RoomType } from '../../../../shared/types/cms'

export interface PageContextInterface {
  localizedPaths?: Record<string, string>
  roomType?: RoomType
}

const defaultState: PageContextInterface = {}

export const PageContext = React.createContext(defaultState)

const PageContextProvider: React.FunctionComponent<{ pageContext: PageContextInterface }> = ({
  children,
  pageContext,
}) => {
  return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
}

export default PageContextProvider
