import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AuthContext, AuthStatus } from '../contexts/authContext'
import UserMenu from './user-menu'

export const LoginButton: React.FC = () => {
  const { t } = useTranslation()
  const { setAuthFlowVisible, authStatus } = React.useContext(AuthContext)

  return (
    <>
      {authStatus === AuthStatus.SignedIn ? (
        <UserMenu />
      ) : (
        <button onClick={() => setAuthFlowVisible(true)} className="button button--login">
          {t(`Log in`)}
        </button>
      )}
    </>
  )
}
export default LoginButton
