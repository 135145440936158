import React from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { enGB, de } from 'date-fns/locale'
import moment from 'moment'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { dateFormat, validDays } from '../../../../shared/config/date'
import isMobile from 'is-mobile'

const locales: Record<string, Locale> = { en: enGB, de: de }
interface DatePickerProps {
  className?: string
  id?: string
  name?: string
  value: string | undefined
  placeholderText: string
  onChange: (date: string) => void
  isBirthDate?: boolean
  isVisitDate?: boolean
}

export const getNextValidDate = (): string => {
  const now = moment()
  now.add(1, `M`)
  now.set(`D`, validDays[0])
  return now.format(dateFormat)
}

export const isValidBookingDate = (date: string | Date): boolean => {
  let valid = false
  let inputDate
  if (typeof date === `string`) inputDate = moment(date, dateFormat)
  else inputDate = moment(date)

  if (validDays.includes(inputDate.get(`D`)) && inputDate.isAfter()) valid = true
  return valid
}

export const isValidBirthDate = (date: string | Date): boolean => {
  let valid = false
  let inputDate
  if (typeof date === `string`) inputDate = moment(date, dateFormat)
  else inputDate = moment(date)

  if (inputDate.isBefore()) valid = true
  return valid
}

export const isValidVisitDate = (date: string | Date): boolean => {
  let valid = false
  let inputDate
  if (typeof date === `string`) inputDate = moment(date, dateFormat)
  else inputDate = moment(date)

  if (inputDate.isBefore()) valid = true
  return valid
}

export const DatePicker: React.FC<DatePickerProps> = ({
  className = ``,
  id = undefined,
  name = undefined,
  value = undefined,
  placeholderText,
  onChange,
  isBirthDate = false,
  isVisitDate = false,
}) => {
  const { language } = useI18next()
  const pickerRef = React.useRef<ReactDatePicker>(null)

  const selectedDate = React.useMemo(() => {
    const theMoment = moment(value, dateFormat)
    if (!theMoment.isValid()) return null
    return theMoment.toDate()
  }, [value])

  return (
    <div className="custom-datepicker__wrapper">
      <ReactDatePicker
        ref={pickerRef}
        locale={locales[language]}
        id={id}
        name={name}
        className={className}
        calendarClassName={`custom-datepicker`}
        calendarStartDay={1}
        placeholderText={placeholderText}
        dateFormat="dd.MM.yyyy"
        selected={selectedDate}
        onChange={(date) => {
          if (date instanceof Date) {
            const theMoment = moment(date)
            onChange(theMoment.format(dateFormat))
          }
        }}
        filterDate={isBirthDate ? isValidBirthDate : isVisitDate ? isValidVisitDate : isValidBookingDate}
        showYearDropdown={isBirthDate}
        yearDropdownItemNumber={150}
        scrollableYearDropdown
        minDate={isBirthDate || isVisitDate ? undefined : moment().toDate()}
        maxDate={isBirthDate ? moment().subtract(18, `years`).toDate() : isVisitDate ? moment().toDate() : undefined}
        onFocus={() => {
          if (isMobile({ featureDetect: true })) {
            // @ts-ignore
            if (pickerRef?.current?.input) {
              // @ts-ignore
              setTimeout(() => pickerRef?.current?.input?.scrollIntoView(), 100)
              // Delayed because phone browser tries to scroll the input to the middle of the window
            }
          }
        }}
        popperPlacement="bottom"
        popperModifiers={[{ name: `flip`, enabled: false }]}
      />
    </div>
  )
}
export default DatePicker
