import { AmenityComponent, Location } from '../../../../../shared/types/cms'
import { CmsService } from './cms'

export class CmsLocationsService extends CmsService<Location> {
  urlPrefix = `locations`

  getLocalDescription(instance: Location | null | undefined, language: string): string {
    if (!instance) return ``
    if (language.toLowerCase() === `en` && instance.descriptionEn) return instance.descriptionEn
    return !!instance.description ? instance.description : ``
  }

  getLocalAmenityName(instance: AmenityComponent | null | undefined, language: string): string {
    if (!instance) return ``
    if (language.toLowerCase() === `en` && instance.label) return instance.labelEn
    return !!instance.label ? instance.label : ``
  }
}
