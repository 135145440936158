import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchContext } from '../contexts/searchContext'

export enum RoomSorting {
  None = `-`,
  PriceAscending = `Lowest price first`,
  PriceDescending = `Highest price first`,
}

export const ListingsSortSelector: React.FC = () => {
  const { t } = useTranslation()
  const searchContext = useContext(SearchContext)

  return (
    <div className="listings__sort-selector col align-self-start d-flex align-items-center">
      <label className="text-nowrap" htmlFor="sortSelector">
        {t(`Sort by`)}:
      </label>
      <select
        className="form-control"
        id="sortSelector"
        value={searchContext.selectedSort}
        onChange={(event) => searchContext.setSelectedSort(event.target.value as RoomSorting)}
      >
        {Object.keys(RoomSorting).map((key) => (
          <option key={`sorting_option_` + key} value={(RoomSorting as Record<string, string>)[key]}>
            {t((RoomSorting as Record<string, string>)[key])}
          </option>
        ))}
      </select>
    </div>
  )
}
