import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AuthContext } from '../contexts/authContext'
import logoutIcon from '../images/icon-logout.svg'
import { Link } from 'gatsby-plugin-react-i18next'

export const UserMenu: React.FC = () => {
  const { t } = useTranslation()
  const { signOut } = React.useContext(AuthContext)
  const [collapsedMenu, setCollapsedMenu] = React.useState(true)

  return (
    <div className="user-menu">
      <button className="user-menu__button" onClick={() => setCollapsedMenu(!collapsedMenu)}>
        <i className="icomoon icomoon-user user-menu__button-icon" />
      </button>
      <div className={`user-menu__floating-menu ${collapsedMenu ? `d-none` : `d-block`}`}>
        <div>
          <Link to="/account">{t(`Profile`)}</Link>
        </div>
        <div>
          <Link to="/account#payment">{t(`Payment info`)}</Link>
        </div>
        <div>
          <Link to="/account#history">{t(`Contracts`)}</Link>
        </div>
        <div className="horizontal-separator"></div>
        <div
          className="cursor-pointer"
          onClick={() => {
            signOut()
          }}
        >
          {t(`Log out`)} <img src={logoutIcon} />
        </div>
      </div>
    </div>
  )
}
export default UserMenu
