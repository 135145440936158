import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js'

export class CognitoService {
  private readonly userPool: CognitoUserPool
  private currentUser: CognitoUser | null

  constructor(userPoolId: string, clientId: string) {
    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    })

    this.currentUser = this.userPool.getCurrentUser()
  }

  getCurrentUser() {
    return this.currentUser
  }

  getCognitoUser(email: string): CognitoUser {
    return new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })
  }

  async getSession(): Promise<CognitoUserSession | null> {
    if (!this.currentUser) {
      // attempt to load once more
      this.currentUser = this.userPool.getCurrentUser()
    }

    if (!this.currentUser) {
      return null
    }

    // @ts-ignore
    return new Promise((resolve, reject) => {
      this.currentUser?.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      })
    })
  }

  async signUp(email: string, password: string): Promise<ISignUpResult> {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({
          Name: `email`,
          Value: email,
        }),
      ]

      this.userPool.signUp(email, password, attributeList, [], (err, res) => {
        if (err) {
          reject(err)
        } else {
          // @ts-ignore
          resolve(res)
        }
      })
    })
  }

  async verifyCode(email: string, code: string): Promise<string> {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  async signIn(email: string, password: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      })
      this.currentUser = this.getCognitoUser(email)

      this.currentUser.authenticateUser(authenticationDetails, {
        onSuccess: (res) => {
          resolve(res)
        },
        onFailure: (err: any) => {
          reject(err)
        },
      })
    })
  }

  async signOut(): Promise<void> {
    this.currentUser?.signOut()
    this.currentUser = null
  }

  async getAttributes(): Promise<CognitoUserAttribute[]> {
    return new Promise((resolve, reject) => {
      this.currentUser?.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err)
        } else {
          resolve(attributes ?? [])
        }
      })
    })
  }

  async setAttribute(attribute: ICognitoUserAttributeData): Promise<string | null> {
    if (!this.currentUser) {
      return null
    }

    return new Promise((resolve, reject) => {
      const attributeList = []
      const res = new CognitoUserAttribute(attribute)
      attributeList.push(res)

      this.currentUser?.updateAttributes(attributeList, (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res ?? null)
        }
      })
    })
  }

  async sendCode(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) {
        reject(`could not find ${email}`)
        return
      }

      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  async forgotPassword(email: string, code: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) {
        reject(`could not find ${email}`)
        return
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<string | null> {
    if (!this.currentUser) {
      return null
    }

    return new Promise((resolve, reject) => {
      this.currentUser?.changePassword(oldPassword, newPassword, (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res ?? null)
        }
      })
    })
  }
}
