import { CmsAuthService } from './auth'
import { CmsRoomTypesService } from './room-types'
import { CmsApplicationService } from './application'
import { Cognito } from '../cognito'
import { CmsRoomServicesService } from './room-services'
import { CmsTenantService } from './tenant'
import { CmsLocationsService } from './locations'

export const CmsUrl = process.env.GATSBY_CMS_URL || ``
export const CmsAuth = new CmsAuthService(CmsUrl)
export const CmsRoomTypes = new CmsRoomTypesService(CmsUrl, Cognito)
export const CmsRoomServices = new CmsRoomServicesService(CmsUrl, Cognito)
export const CmsApplication = new CmsApplicationService(CmsUrl, Cognito)
export const CmsTenant = new CmsTenantService(CmsUrl, Cognito)
export const CmsLocations = new CmsLocationsService(CmsUrl, Cognito)
