import { RoomService, Image } from '../../../../../shared/types/cms'
import { CmsService } from './cms'

export class CmsRoomServicesService extends CmsService<RoomService & { price: number }> {
  urlPrefix = `room-services`

  getLocalName(roomService: RoomService | null, language: string): string {
    if (roomService === null) return ``
    if (language.toLowerCase() === `en` && roomService.nameEn) return roomService.nameEn
    return roomService.name
  }

  getLocalDescription(roomService: RoomService | null, language: string): string {
    if (roomService === null) return ``
    if (language.toLowerCase() === `en` && roomService.descriptionEn) return roomService.descriptionEn
    return !!roomService.description ? roomService.description : ``
  }

  getLocalImage(roomService: RoomService | null, language: string): Image | null {
    if (roomService === null) return null
    if (language.toLowerCase() === `en` && roomService?.imageEn?.url) return roomService.imageEn
    return !!roomService.image ? roomService.image : null
  }
}
