import * as React from 'react'
import LogoZymma from '../../images/zymma-logo-neg.inline.svg'
import LoginButton from '../login-button'
import LanguageSelector from '../language-selector'
import { Link } from 'gatsby-plugin-react-i18next'
import menuBurger from '../../images/menu-burger.svg'
import NavMenu from './nav-menu'

const MobileNav: React.FC<{ fluid?: boolean }> = ({ fluid = false }) => {
  const [menuVisible, setMenuVisible] = React.useState(false)

  return (
    <>
      <div className={`mobile-header__navbar ${menuVisible ? `mobile-header__navbar--open` : ``}`}>
        <div className={`d-flex w-100 align-items-center justify-content-between`}>
          <Link className="d-block" to="/">
            <LogoZymma />
          </Link>
          <LanguageSelector />

          <LoginButton />
          <div className="mobile-header__navbar-burger" onClick={() => setMenuVisible(!menuVisible)}>
            <img src={menuBurger} />
          </div>
        </div>
      </div>
      <NavMenu isOpen={menuVisible} onClose={() => setMenuVisible(false)} />
    </>
  )
}

export default MobileNav
