import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { SiteLocale, Tenant } from '../../../../../shared/types/cms'

export class CmsAuthService {
  constructor(private cmsUrl: string) {}

  async connect(session: CognitoUserSession, locale: SiteLocale): Promise<Tenant> {
    let response = await fetch(`${this.cmsUrl}/auth/connect-cognito`, {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        token: session.getIdToken().getJwtToken(),
        locale: locale,
      }),
    })

    response = await response.json()

    if (`error` in response) {
      throw new Error(`Failed to connect tenant`)
    }

    return response as unknown as Tenant
  }
}
