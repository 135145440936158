import { graphql, useStaticQuery } from 'gatsby'
import { Location } from '../../../../shared/types/cms'

export interface LocationOptionsResult {
  allStrapiLocations: {
    edges: {
      node: Omit<Location, 'id'> & { id: string }
    }[]
  }
}

export const useLocationOptions = (): { locations: Array<Omit<Location, 'id'> & { id: number | string }> } => {
  const locationOptions: LocationOptionsResult = useStaticQuery(graphql`
    {
      allStrapiLocations {
        edges {
          node {
            id
            slug
            name
            district
            description
            descriptionEn
            backgroundImage {
              formats {
                small {
                  url
                }
              }
              url
            }
            startingPrice
            buildingAmenities {
              label
              labelEn
              icon {
                url
              }
            }
            locationAmenities {
              label
              labelEn
              icon {
                url
              }
            }
            virtualTour {
              url
              image {
                url
              }
            }
          }
        }
      }
    }
  `)

  return {
    locations: locationOptions.allStrapiLocations.edges.map((edge) => ({
      ...edge.node,
      id: edge.node.id.replace(/^[^_]+_/, ``), // Strapi gatsby source prefixes the id
    })),
  }
}
