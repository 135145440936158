import * as React from 'react'
import LogoZymma from '../../images/zymma-logo-neg.inline.svg'
import LanguageSelector from '../language-selector'
import LoginButton from '../login-button'
import SearchBar from '../search-bar'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import MobileHeader from '../mobile-header'
import heroBackground from '../../images/hero-background.jpg'
import curvedArrowDownright from '../../images/curved-arrow-downright.png'
import menuBurger from '../../images/menu-burger.svg'
import NavMenu from './nav-menu'
import { SearchContext } from '../../contexts/searchContext'
import StickyHeader from './sticky-header'

type HeaderProps = {
  variant?: 'home' | 'listings' | 'default'
}

export const Header: React.FC<HeaderProps> = ({ variant = `default` }) => {
  const { t } = useTranslation()
  const headerRef = React.useRef<HTMLInputElement>(null)
  const [menuVisible, setMenuVisible] = React.useState(false)
  const search = React.useContext(SearchContext)

  return (
    <>
      <header ref={headerRef} className={`header ${variant ? `header--${variant}` : ``}`}>
        <MobileHeader />
        <StickyHeader />
        <nav className="navbar">
          <div className="container-fluid navbar__container">
            <Link to="/" className="navbar-brand navbar__title">
              <LogoZymma />
            </Link>

            <div className="navbar__right d-flex align-items-center">
              <LanguageSelector />
              <img
                className="navbar__right-burger cursor-pointer"
                src={menuBurger}
                onClick={() => setMenuVisible(true)}
              />
              <LoginButton />
            </div>
          </div>
          <NavMenu isOpen={menuVisible} onClose={() => setMenuVisible(false)} />
        </nav>
        <div className="header__hero">
          <img className="header__hero-background" src={heroBackground} />
          <div className="header__hero-text">{t(`find your new room`)}</div>
          <div className="header__hero-content row">
            <div className={`header__hero-cta ${search.locationChanged ? `header__hero-cta--hidden` : ``}`}>
              {t(`Find your room in your city`)}
              <img className="header__hero-cta-arrow" src={curvedArrowDownright} />
            </div>
            <SearchBar />
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
