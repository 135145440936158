import { RoomTypeAvailable, RoomType } from '../../../../../shared/types/cms'
import { CmsService } from './cms'

export class CmsRoomTypesService extends CmsService<RoomType> {
  urlPrefix = `room-types`

  async getAvailable(location: string, startDate: string): Promise<RoomTypeAvailable[]> {
    const queryData: { location: string; startDate: string } = {
      location: location,
      startDate: startDate,
    }
    const urlParams = new URLSearchParams(queryData)

    const response = await this.request<RoomTypeAvailable[]>(`available?${urlParams.toString()}`)
    if (`error` in response) {
      throw new Error(`Failed to retrieve available rooms`)
    }

    return response
  }

  getLocalName(roomType: RoomType | RoomTypeAvailable | null, language: string): string {
    if (roomType === null) return ``
    if (language.toLowerCase() === `en` && roomType.nameEn) return roomType.nameEn
    return roomType.name
  }

  getLocalDescription(roomType: RoomType | null, language: string): string {
    if (roomType === null) return ``
    if (language.toLowerCase() === `en` && roomType.descriptionEn) return roomType.descriptionEn
    return !!roomType.description ? roomType.description : ``
  }

  getSizeString(roomType: RoomType | RoomTypeAvailable | null): string {
    if (!roomType) {
      return ``
    }

    if (roomType.spaceMin && roomType.spaceMin === roomType.spaceMax) {
      return `${roomType.spaceMin.toFixed(1)}m²`
    }

    return `${roomType.spaceMin || ``} - ${roomType.spaceMax || ``}m²`
  }
}
