import React, { ButtonHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

export const LoadingButton: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    loading: boolean
    type?: 'button' | 'submit' | 'reset'
    disabled?: boolean
    showLoadingText?: boolean
  }
> = ({ loading = false, type, disabled = false, showLoadingText = true, ...props }) => {
  const { t } = useTranslation()
  return (
    <button
      {...props}
      className={`loading-button ${loading ? `` : `loading-button--loading`} ${
        disabled ? `` : `loading-button--disabled`
      } ${props.className ?? ``} ${showLoadingText ? `` : `loading-button--notext`}`}
      disabled={disabled}
      type={type}
      onClick={loading ? undefined : props.onClick}
    >
      {loading ? (
        <>
          <div className="spinner-grow" role="status" />
          {showLoadingText && <>{t(`Loading`)}...</>}
        </>
      ) : (
        props.children
      )}
    </button>
  )
}

export default LoadingButton
