import { Application, NewApplication } from '../../../../../shared/types/cms'
import moment from 'moment'
import { dateFormat } from '../../../../../shared/config/date'
import { CmsService } from './cms'
import { FileWithPreview } from '../../components/documents-uploader'

export class CmsApplicationService extends CmsService<Application> {
  urlPrefix = `applications`

  async create(data: NewApplication): Promise<Application> {
    const formData = new FormData()

    const passportPhotos = data.personalInfo.passportPhotos as FileWithPreview[]
    passportPhotos.map((document, index) => {
      if (!document.id) {
        formData.append(`files.passportPhotos[${index}]`, document)
      }
    })
    const studentCertificate = data.personalInfo.studentCertificate as FileWithPreview[]
    studentCertificate.map((document, index) => {
      if (!document.id) {
        formData.append(`files.studentCertificate[${index}]`, document)
      }
    })
    const studentProofOfIncomeDocument = data.personalInfo.studentProofOfIncomeDocument as FileWithPreview[]
    studentProofOfIncomeDocument.map((document, index) => {
      if (!document.id) {
        formData.append(`files.studentProofOfIncomeDocument[${index}]`, document)
      }
    })

    data.personalInfo.passportPhotos = passportPhotos.filter((photo) => !!photo.id)
    data.personalInfo.studentCertificate = studentCertificate.filter((photo) => !!photo.id)
    data.personalInfo.studentProofOfIncomeDocument = studentProofOfIncomeDocument.filter((photo) => !!photo.id)

    formData.append(
      `data`,
      JSON.stringify({
        ...data,
        startDate: moment(data.startDate, dateFormat).format(`YYYY-MM-DD`),
      }),
    )

    const response = await this.request<Application>(
      ``,
      {
        method: `POST`,
        body: formData,
      },
      { skipContentTypeHeader: true },
    )

    if (`error` in response) {
      throw new Error(`Something went wrong during application creation`)
    }

    return response
  }
}
