import React from 'react'
import { Cognito } from '../services/cognito'
import { CognitoUserSession, CognitoUserAttribute, ICognitoUserAttributeData } from 'amazon-cognito-identity-js'
import { CmsAuth, CmsTenant } from '../services/cms'
import AuthFlowStateType from '../interfaces/auth-flow-state'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { SiteLocale, Tenant } from '../../../../shared/types/cms'
import once from 'lodash/once'

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface AuthInterface {
  authFlowVisible: boolean
  authFlowState: AuthFlowStateType
  attrInfo: CognitoUserAttribute[]
  authStatus: AuthStatus
  signIn: (email: string, password: string) => Promise<void>
  signUp: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  verifyCode: (email: string, code: string) => Promise<void>
  getSession: () => Promise<CognitoUserSession | null>
  getAttributes: () => Promise<CognitoUserAttribute[]>
  setAttribute: (attr: ICognitoUserAttributeData) => Promise<void>
  sendCode: (email: string) => Promise<void>
  forgotPassword: (email: string, code: string, password: string) => Promise<void>
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>
  setAuthFlowVisible: (authFlowVisible: boolean) => void
  setAuthFlowState: (authFlowState: AuthFlowStateType) => void
  isBusy: boolean
  tenant: Tenant | null
  loadTenant: () => Promise<void>
}

const defaultState: AuthInterface = {
  authFlowVisible: false,
  authFlowState: `signin`,
  attrInfo: [],
  authStatus: AuthStatus.Loading,
  signIn: async () => undefined,
  signUp: async () => undefined,
  signOut: async () => undefined,
  verifyCode: async () => undefined,
  getSession: async () => null,
  getAttributes: async () => [],
  setAttribute: async () => undefined,
  sendCode: async () => undefined,
  forgotPassword: async () => undefined,
  changePassword: async () => undefined,
  setAuthFlowVisible: () => null,
  setAuthFlowState: () => null,
  isBusy: false,
  tenant: null,
  loadTenant: async () => undefined,
}

export const AuthContext = React.createContext(defaultState)

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [authFlowVisible, _setAuthFlowVisible] = React.useState(defaultState.authFlowVisible)
  const [authFlowState, _setAuthFlowState] = React.useState<AuthFlowStateType>(defaultState.authFlowState)
  const [authStatus, setAuthStatus] = React.useState<AuthStatus>(AuthStatus.Loading)
  const [attrInfo, setAttrInfo] = React.useState<CognitoUserAttribute[]>([])
  const [isBusy, setIsBusy] = React.useState(false)
  const [tenant, setTenant] = React.useState<Tenant | null>(null)
  const { language } = useI18next()
  const { navigate } = useI18next()

  React.useEffect(() => {
    async function getSessionInfo() {
      try {
        const session = await getSession()

        if (!session) {
          setAuthStatus(AuthStatus.SignedOut)

          return
        }

        const attr = await getAttributes()
        setAttrInfo(attr)
        setAuthStatus(AuthStatus.SignedIn)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
    getSessionInfo().catch(console.error)
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signIn(email: string, password: string): Promise<void> {
    setIsBusy(true)
    try {
      await Cognito.signOut()

      const res = await Cognito.signIn(email, password)

      setTenant(await CmsAuth.connect(res, language as SiteLocale))

      setAuthStatus(AuthStatus.SignedIn)
      setIsBusy(false)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      setIsBusy(false)
      throw err
    }
  }

  async function signUp(email: string, password: string): Promise<void> {
    setIsBusy(true)
    try {
      await Cognito.signUp(email, password)
      setIsBusy(false)
    } catch (err) {
      setIsBusy(false)
      throw err
    }
  }

  async function signOut(): Promise<void> {
    setIsBusy(true)
    await Cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
    setIsBusy(false)
    navigate(`/`)
  }

  async function verifyCode(username: string, code: string): Promise<void> {
    setIsBusy(true)
    try {
      await Cognito.verifyCode(username, code)
      setIsBusy(false)
    } catch (err) {
      setIsBusy(false)
      throw err
    }
  }

  async function getSession(): Promise<CognitoUserSession | null> {
    return await Cognito.getSession()
  }

  async function getAttributes(): Promise<CognitoUserAttribute[]> {
    return await Cognito.getAttributes()
  }

  async function setAttribute(attr: ICognitoUserAttributeData): Promise<void> {
    await Cognito.setAttribute(attr)
  }

  async function sendCode(email: string): Promise<void> {
    setIsBusy(true)
    try {
      await Cognito.sendCode(email)
      setIsBusy(false)
    } catch (err) {
      setIsBusy(false)
      throw err
    }
  }

  async function forgotPassword(email: string, code: string, password: string): Promise<void> {
    setIsBusy(true)
    try {
      await Cognito.forgotPassword(email, code, password)
      setIsBusy(false)
    } catch (err) {
      setIsBusy(false)
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
    setIsBusy(true)
    try {
      await Cognito.changePassword(oldPassword, newPassword)
      setIsBusy(false)
    } catch (err) {
      setIsBusy(false)
      throw err
    }
  }

  async function loadTenant(): Promise<void> {
    setIsBusy(true)
    try {
      if (authStatus === AuthStatus.SignedIn) {
        setTenant(await CmsTenant.getMe())
      }
      setIsBusy(false)
    } catch (err) {
      setIsBusy(false)
      throw err
    }
  }

  const state: AuthInterface = {
    authFlowVisible,
    authFlowState,
    authStatus,
    attrInfo,
    signUp,
    signIn,
    signOut,
    verifyCode,
    getSession,
    getAttributes,
    setAttribute,
    sendCode,
    forgotPassword,
    changePassword,
    setAuthFlowVisible: _setAuthFlowVisible,
    setAuthFlowState: _setAuthFlowState,
    isBusy,
    tenant,
    loadTenant: once(loadTenant),
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
